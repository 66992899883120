import logo from './inoit_noback.png';
import './App.css';
import React, { Suspense, useRef } from "react";
import NavigationBar from './components/NavigationBar';
import { Container, Row, Col } from 'react-bootstrap';
import { ThreeDBox } from './components/3dBox';
import { Canvas, useThree } from "@react-three/fiber";
import { Environment } from '@react-three/drei'
import { PresentationControls, Float, ContactShadows } from '@react-three/drei'
import { Box } from './components/newbox';



function App() {

  return (
    <div className="App">
      <NavigationBar/>
      <Container style={{ height: '100vh', width: '80vw' }}>

        <Row style={{marginTop: '15vh'}}>
          <Col>
              <h1>¡Bienvenidos a INOÏT!</h1>
              <h4>Únete a nuestra comunidad y disfruta de un cambio de armario constante sin preocuparte por absolutamente nada!</h4>
          </Col>
          <Col style={{overflow: 'auto'}}>
            <Canvas shadows style={{ position: 'relative' }}>
              <ambientLight />
              <Suspense fallback={null}>
                <Float speed={5.4} rotationIntensity={5.5} floatIntensity={0.5}>
                  <ThreeDBox />
                  <Environment preset="sunset" />
                </Float>
              </Suspense>
            </Canvas>
          </Col>
        </Row>

      </Container>

    </div>
  );
}


export default App;
