import { Container, Nav, Navbar, Button } from 'react-bootstrap';
import './NavigationBar.css'

function NavigationBar() {
    return (
        <Navbar collapseOnSelect expand="lg" className="navigationbar">
            <Container >
                <Navbar.Brand href="#home">
                    <img
                        src="../inoit_noback.png"
                        width="95"
                        height="70"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
                    <Nav>
                        <Nav.Link style={{paddingLeft: '20px', paddingRight: '20px'}} href="#como">Cómo funciona</Nav.Link>
                        <Nav.Link style={{paddingLeft: '20px', paddingRight: '20px'}} eventKey={2} href="#nosotros">Sobre nosotros</Nav.Link>
                        <Nav.Link style={{paddingLeft: '20px', paddingRight: '20px'}} eventKey={2} href="#sub">Subscripción</Nav.Link>
                        <Nav.Link style={{paddingLeft: '20px', paddingRight: '20px'}} eventKey={2} href="#contact">Contacto</Nav.Link>
                        <button className='navbtn'>Únete</button>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavigationBar;