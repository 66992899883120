
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function ThreeDBox(props) {
  const { nodes, materials } = useGLTF("/box.glb");
  return (
    <group {...props} dispose={null} scale={1}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube.geometry}
        material={materials.Material}
        position={[0.019, 0.966, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path503002.geometry}
        material={materials["SVGMat.031"]}
        position={[-1.267, 1.962, 0.827]}
        rotation={[0.023, 0, 0]}
        scale={7.154}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path2819001.geometry}
        material={materials["SVGMat.059"]}
        position={[1.108, -1.035, 2.069]}
        rotation={[1.571, 0.021, -1.575]}
        scale={11.925}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path2819002.geometry}
        material={materials["SVGMat.060"]}
        position={[-0.932, -1.035, 2.099]}
        rotation={[1.571, 0.021, -1.599]}
        scale={11.925}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path2819004.geometry}
        material={materials["SVGMat.062"]}
        position={[-2.094, -1.035, 1.074]}
        rotation={[1.55, 0, 0]}
        scale={11.925}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path2819003.geometry}
        material={materials["SVGMat.064"]}
        position={[-2.094, -1.035, -0.973]}
        rotation={[1.55, 0, 0]}
        scale={11.925}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path2819.geometry}
        material={materials["SVGMat.058"]}
        position={[-2.094, -1.035, 1.074]}
        rotation={[1.55, 0, 0]}
        scale={11.925}
      />
    </group>
  );
}

useGLTF.preload("/box.glb");



